import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className="container content">
      <h1>About</h1>
      <p>
        FIEMA, which stands for 'Friends of Indian Evangelical Mission
        Australia', are Christians in Australia who have entered into
        partnership with the Indian Evangelical Mission(IEM), to support,
        promote, and pray for the Mission.
      </p>
      <p>
        FIEMA seeks to promote the interests of the Indian Evangelical Mission
        by informing Australian Christians about the work done by IEM
        missionaries, and raising financial and prayer support for the Mission.
      </p>
      <h3>Our Mission</h3>
      <p>
        To take the Gospel of Jesus Christ to the un evangelized areas in India
        and abroad and to plant churches among unreached people groups.
      </p>
      <h3>Our Vision</h3>
      <p>
          The Gospel to every person and a Church among every people group.
      </p>
      <h3>Our Pillars</h3>
      <p>
        Vision, Faith and sacrifice
      </p>
      <h3>IEM Basics</h3>
      <p>
        <ul>
          <li>IEM is a <strong>Prayer Movement</strong> depending on God for its resources and guidance</li>
          <li>IEM is a <strong>Pioneering Movement</strong> in moving to the new places to share the message of Christ</li>
          <li>IEM is a <strong>People Movement</strong> by identifying, developing people and entrusting the work</li>
          <li>IEM is a <strong>Partnership Movement</strong> in sharing the message of Christ in the communities for holistic impact through partnership with the churches</li>
          <li>It is an interdenominational and indigenous Mission in its outlook and operation</li>
          <li>It looks to God for all its concerns by faith</li>
        </ul>       
      </p>
      <h3>IEM Today</h3>
      <p>
        IEM has celebrated 50 years in 2015. We have 930 Mission workers serving
        in 28 States among 146 different people groups. Around 25 missionaries are
       recruited every year. We are involved in Evangelism, Church planting,
        Bible translation and Scripture engagement. In 59 languages, Literacy
        work, Tribal hostels and Day care centres, Medical ministry, socio
        economic development work, relief work, Leadership development and Short
        term Bible schools. We conduct workshops for families, Church leaders and
        Christian workers in different churches. We publish our Outreach magazine
        in different languages.
       </p>
      <h3>Our Charity Registration in Australia</h3>
      <p>
        FIEMA operates under two companies registered under the Australian Charities and Not-for-profits Commission Act 2012
      </p>
      <ul>
          <li>Friends of IEM Australia Ltd</li>
          <li>Friends of IEM Australia Relief and Development Ltd</li>
      </ul>
      <p>
            <strong>Friends of IEM Australia Ltd</strong> is a not-for-profit charity with an objective to advance Christian faith particularly focusing on– 
              <ul>
                <li>Motivating and challenging Christians for world outreach and Christian care </li>
                <li>Promoting the mission and ministries of Indian Evangelical Mission India </li>
              </ul>
            <a href="https://www.acnc.gov.au/charity/charities/1067ef5a-451f-ec11-b6e6-000d3acbb2ae/profile" target="_blank" rel="noopener noreferrer">FIEMA ACNC Profile</a>
      </p>
      <p>
           <strong>FIEMA Relief and Development Ltd</strong> is a not-for-profit charity with an objective to provide public benevolent relief in India.
           <br/>
           <a href="https://www.acnc.gov.au/charity/charities/d2ac3b8e-0120-ec11-b6e6-000d3acbb7bf/profile" target="_blank" rel="noopener noreferrer">FIEMA R&amp;D ACNC Profile</a>
      </p>   
    </div>
  </Layout>
)

export default About
